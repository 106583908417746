<template>
  <router-view />
</template>

<style scoped>
#app-base {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<script>
import { defineComponent } from 'vue';
// import MainLayout from './layouts/Main.vue';

export default defineComponent({
  name: 'App',
  components: {
    // MainLayout
  },
});
</script>
