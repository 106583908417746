// @ts-nocheck
// import { h } from 'vue';
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    name: 'Home',
    path: '/',
    component: () => import('@/views/Home.vue'),
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/404.vue'),
  },
  {
    name: 'QRCodeGenerator',
    path: '/qrcode/generator',
    component: () => import('@/views/QRCodeGenerator.vue'),
  },
  {
    name: 'QRCodeScanner',
    path: '/qrcode/scanner',
    component: () => import('@/views/QRCodeScanner.vue'),
  },
  {
    name: 'Encryptor',
    path: '/encryptor',
    component: () => import('@/views/GeneralEncryptor.vue'),
  },
  {
    name: 'Decryptor',
    path: '/decryptor',
    component: () => import('@/views/GeneralDecryptor.vue'),
  },
  {
    name: 'PrivateKey',
    path: '/pk',
    alias: ['/privkey', '/pkey', '/key'],
    component: () => import('@/views/PrivateKeyGenerator.vue'),
  },
  {
    name: 'Seed',
    path: '/seed',
    alias: ['/mnemonic'],
    component: () => import('@/views/SeedGenerator.vue'),
  },
  {
    name: 'Secrets',
    path: '/secrets',
    alias: ['/secretsharing', '/shamirs', '/password', '/passphrase'],
    component: () => import('@/views/SecretsGenerator.vue'),
  },
  {
    name: 'Combines',
    path: '/combines',
    alias: ['/combinesecrets'],
    component: () => import('@/views/SecretsCombine.vue'),
  },
  {
    name: 'Help',
    path: '/help',
    component: () => import('@/views/Help.vue'),
  },
  {
    name: 'CreateKeypair',
    path: '/common/keypair',
    component: () => import('@/views/CreateKeypair.vue'),
  },
  {
    name: 'EncryptUsingRSAKey',
    path: '/common/encrypt',
    component: () => import('@/views/EncryptUsingRSAKey.vue'),
  },
  {
    name: 'DecryptUsingRSAKey',
    path: '/common/decrypt',
    component: () => import('@/views/DecryptUsingRSAKey.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

export default router
